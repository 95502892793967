import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import classnames from "classnames";
import React, { useContext, useState } from "react";
import { MetricReport, Query } from "../../../lib/api/charts";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { match, Route, Redirect } from "react-router";
import { CustomDashboardOuter } from "../../../charts/dashboard-outer/CustomDashboardOuter";
import { history } from "../../../lib/history";
import { margin } from "../../../style/theme";
import { CustomDealerContext } from "../../../contexts/CustomDealerContext";
import { Loading } from "../../../components/Loading";
import { CustomDashboardMeasures as BaseMeasures } from "../../../charts/CustomDashReports";
import { MetricInsightContext } from "../../../contexts/MetricInsightContext";
import { CustomMetricContext } from "../../../contexts/Custom/MetricContext";
import { CustomMetricTable } from "../../../dashboards/CustomTable";
import { CustomMetricTableContext } from "../../../contexts/Custom/MetricTableContext";
import { CustomDashboardTabs } from "../CustomTabs";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { GuidanceModal } from "../../../components/GuidanceModal";
import { FormattedMessage, useIntl } from "react-intl";
import { MeasuresContext } from "../../../contexts/MeasuresContext";
import { DealerModelsContext } from "../../../contexts/Custom/DealerModelsContext";
import { Measures as AllMeasures } from "./Selectors";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { CurrencyContext } from "../../../contexts/CurrencyContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: margin.width,
      margin: "auto",
      backgroundColor: "#fff",
      color: "#191919",
      padding: "1rem",
      borderRadius: "5px",
      border: "solid 1px #e0e0e0",
    },
    table: {
      paddingTop: `${theme.spacing.unit * 2}px`,
      padddingBottom: `${theme.spacing.unit * 2}px`,
      overflowX: "scroll",
      [theme.breakpoints.up("lg")]: {
        overflowX: "unset",
      },
    },
    guidance: {
      gridArea: "guidance",
      overflow: "hidden",
    },
    centered: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bold: {
      fontWeight: "bold",
    },
    scroll: {
      overflowY: "scroll",
    },
    rowed: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "10px",
    },
    guide: {
      width: "400px",
      height: "550px",
      overflowY: "scroll",
      padding: theme.spacing.unit * 2,
    },
    popper: {
      zIndex: 99999,
    },
    subHeaderSpace: {
      marginLeft: theme.spacing.unit * 3,
      ["@media (max-width:650px)"]: {
        marginLeft: 0,
      },
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.87) !important",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      ["@media (max-width:650px)"]: {
        display: "block",
      },
    },
    subHeader: {
      display: "flex",
      ["@media (max-width:650px)"]: {
        display: "block",
      },
    },
  });

export type MetricInsightsProps = {
  title: string;
  report: MetricReport;
  onClose?: () => void;
  query: Query;
  match?: any;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const monthDict = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const viewOptions = {
  undefined: "Default",
  avg: "Average by Dealer Count",
};

const MetricInsightsUnstlyed: React.FunctionComponent<MetricInsightsProps> = ({ classes, report, query, title, onClose }) => {
  const intl = useIntl();
  const { metricInsight } = React.useContext(MetricInsightContext);
  const { carlineAnalysisMeasures } = React.useContext(DealerModelsContext);

  const CustomDashboardMeasures = {
    ...AllMeasures,
    ...carlineAnalysisMeasures,
  };

  const custom = metricInsight.modelCustom
    ? metricInsight.modelCustom
    : CustomDashboardMeasures[metricInsight.custom] && CustomDashboardMeasures[metricInsight.custom].getMeasure(metricInsight.custom, CustomDashboardMeasures);

  const [open, setOpen] = useState(false);
  const dealer = useContext(CustomDealerContext);
  const { getCustomMetric } = useContext(CustomMetricContext);
  const { metric, loading } = getCustomMetric(report, query, custom);
  const date = new Date(Date.parse(query.date));
  const { getCustomMetricTable } = useContext(CustomMetricTableContext); // Adding these two lines to get get title pulled though, a more 'correct' fix would be to loadit from the getCustomMetric at source
  const { metric: metric1 } = getCustomMetricTable(report, query, custom);

  const { getMeasures } = useContext(MeasuresContext);
  const { language } = React.useContext(LanguageContext);
  const { targetCurrency } = React.useContext(CurrencyContext);
  const getGuidanceStr = (actualTitle: string) => {
    return false;
    const currentMeasure = getMeasures().find(measure => measure.title == actualTitle) || { dashboardNotes: "" };

    if (language != "en" && !!currentMeasure[`${language}_dashboardNotes`]) {
      return currentMeasure[`${language}_dashboardNotes`];
    }

    return currentMeasure.dashboardNotes;
  };

  const [tabvalue, setTabValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => {
    return value != index ? <></> : <>{children}</>;
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const languageFormatPeriod = (period: string) => {
    return intl.formatMessage({ id: `month.${period.toLowerCase()}.label` });
  };

  // If user reloads on custom insights page, redirect to custom dashboard
  if (/custom/.test(report) && (metricInsight.modelCustom ? false : !AllMeasures[metricInsight.custom])) {
    return <Redirect to="/custom/dealer" />;
  }

  if (!loading.loaded) {
    return <Loading />;
  }

  const startDate =
    query.period != "Financial Year"
      ? `${monthDict[0]} ${date.getFullYear()}`
      : `${monthDict[query.financialMonth]} ${query.financialMonth < date.getMonth() ? date.getFullYear() : date.getFullYear() - 1}`;

  return (
    <div className={classnames(classes.root)}>
      <div className={classes.header}>
        <div>
          <Typography variant="subheading" className={classes.bold}>
            <FormattedMessage id="insights.insights.for" />
            &nbsp;{metric1.name}
          </Typography>
          <Typography variant="subheading">
            <span>
              <span className={classes.bold}>
                <FormattedMessage id="insights.dealership.name" />
                :&nbsp;
              </span>
              {query.dealer.dealer}
            </span>
          </Typography>
          <Typography variant="subheading">
            <span>
              <span className={classes.bold}>
                <FormattedMessage id="insights.period" />
                :&nbsp;
              </span>{" "}
              {`${monthDict[date.getMonth()]} ${date.getFullYear()} (YTD Start: ${startDate})`}
              {/* <FormattedMessage id={languageFormatPeriod(monthDict[date.getMonth()])} /> {date.getFullYear()} */}
            </span>
          </Typography>
          <Typography variant="subheading" className={classes.subHeader}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id="insights.report.type" />
                :&nbsp;
              </span>
              {query.brand || intl.formatMessage({ id: "all.brand.label" })}
            </div>
            {query.benchmarks.filter(bm => bm.length > 0).length > 0 && (
              <div className={classes.subHeaderSpace}>
                <span className={classes.bold}>
                  <FormattedMessage id="insights.benchmark" />
                  :&nbsp;
                </span>
                {query.benchmarks.length > 1 ? (
                  <span>
                    {query.benchmarks[0]} | {query.benchmarks[1]}
                  </span>
                ) : (
                  <span>{query.benchmarks[0]}</span>
                )}
              </div>
            )}
            {dealer.customSelected.hasTargets && (
              <div className={classes.subHeaderSpace}>
                <span className={classes.bold}>
                  <FormattedMessage id="insights.forecast" />
                  :&nbsp;
                </span>
                {query.forecast.name || intl.formatMessage({ id: "all.forecast.label" })}
              </div>
            )}
            <div className={classes.subHeaderSpace}>
              <span className={classes.bold}>
                <FormattedMessage id="insights.currency" />
                :&nbsp;
              </span>{" "}
              {targetCurrency || dealer.customSelected.currency}
            </div>
            {query.meta && (
              <div className={classes.subHeaderSpace}>
                <span className={classes.bold}>View:&nbsp;</span> {viewOptions[query.meta.selectedAgg]}
              </div>
            )}
          </Typography>
        </div>
        <div className={classes.rowed}>
          <GuidanceModal header={title || metric.title} open={open} setOpen={setOpen} guidanceHTML={getGuidanceStr(metric.actualTitle)} />
          <Route
            path="/custom/dealer/insight/custom/:department/:id?"
            render={({ match }) => (
              <Button
                classes={{ disabled: classes.disabled }}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (match.params.id !== undefined) {
                    history.push(`/custom/dealer/${match.params.id}`);
                  } else {
                    history.push(`/custom/dealer`);
                  }
                }}
              >
                <FormattedMessage id="back.button" />
              </Button>
            )}
          />
          {getGuidanceStr(metric.actualTitle) && (
            <Button style={{ cursor: "pointer" }} classes={{ disabled: classes.disabled }} onClick={() => setOpen(true)}>
              <FormattedMessage id="guidance.button" />
            </Button>
          )}
          {onClose && <Close style={{ cursor: "pointer" }} onClick={onClose} />}
        </div>
      </div>
      <div className={classes.table}>
        <Tabs value={tabvalue} onChange={handleTabChange}>
          <Tab label={intl.formatMessage({ id: "period.standard.label" })} {...a11yProps(0)} />
          <Tab label={intl.formatMessage({ id: "period.monthly.label" })} {...a11yProps(1)} />
          <Tab label={intl.formatMessage({ id: "period.tmra.label" })} {...a11yProps(2)} />
          <Tab label={intl.formatMessage({ id: "period.quaterly.label" })} {...a11yProps(3)} />
          <Tab label={intl.formatMessage({ id: "period.yearly.label" })} {...a11yProps(4)} />
          <Tab label={intl.formatMessage({ id: "period.yearly2.label" })} {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={tabvalue} index={0}>
          <CustomMetricTable
            report={report}
            query={{ ...query, meta: { ...query.meta, variant: "default" } }}
            title={`${title || metric.title} - ${query.dealer.dealer}`}
            custom={custom}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <CustomMetricTable
            report={report}
            query={{ ...query, meta: { ...query.meta, variant: "singleyear" } }}
            title={`${title || metric.title} - ${query.dealer.dealer}`}
            custom={custom}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={2}>
          <CustomMetricTable
            report={report}
            query={{ ...query, meta: { ...query.meta, variant: "tmra" } }}
            title={`${title || metric.title} - ${query.dealer.dealer}`}
            custom={custom}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={3}>
          <CustomMetricTable
            report={report}
            query={{ ...query, meta: { ...query.meta, variant: "quarter" } }}
            title={`${title || metric.title} - ${query.dealer.dealer}`}
            custom={custom}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={4}>
          <CustomMetricTable
            report={report}
            query={{ ...query, meta: { ...query.meta, variant: "yearly" } }}
            title={`${title || metric.title} - ${query.dealer.dealer}`}
            custom={custom}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={5}>
          <CustomMetricTable
            report={report}
            query={{ ...query, meta: { ...query.meta, variant: "sixyearly" } }}
            title={`${title || metric.title} - ${query.dealer.dealer}`}
            custom={custom}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export const MetricInsights = withStyles(styles)(MetricInsightsUnstlyed);

export type MetricCardModalProps = MetricInsightsProps & {
  isOpen: boolean;
  onClose: () => void;
};

export const MetricCardModalUnstyled: React.FC<MetricCardModalProps> = ({ isOpen, ...props }) => {
  return (
    <Modal open={isOpen} onClose={props.onClose}>
      <MetricInsights {...props} />
    </Modal>
  );
};

export const MetricCardModal = withStyles(styles)(MetricCardModalUnstyled);

const pageStyles = () =>
  createStyles({
    root: {},
  });

export type MetricPageProps = {
  match: match<{
    department: string;
    metric: string;
    dashboard: string;
  }>;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof pageStyles>;

const MetricPageUnstlyed: React.SFC<MetricPageProps> = ({ classes, children, className, match, ...props }) => (
  <div className={classnames(classes.root, className)}>
    <CustomDashboardOuter
      department={match.params.department || "All Department"}
      meta={{ context: match.params.dashboard }}
      showAvgSelector={true}
      renderTabs={() => <CustomDashboardTabs className={classes.root} />}
      render={({ query }) => <MetricInsights title="" {...props} report={match.params.metric as any} query={query} match={match} />}
    />
  </div>
);

export const MetricPage = withStyles(pageStyles)(MetricPageUnstlyed);

export default MetricPage;
